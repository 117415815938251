<template>
    <div style="min-height: 600px;margin-top:3%;">
        <div id="components-anchor-demo-basic">
            <header>查询结果</header>
            <a-row :gutter="20">
                <a-col v-for="(item, index) in searchResult" :key="item.moduleName" :lg="4" :md="12" :xl="4" :xs="24"
                       style="margin: 10px;">
                    <a-card :style="{'borderLeftWidth': '5px', 'borderLeft': 'solid',  'borderLeftColor': cardColor[item.level].borderColor, 'fontSize': '15px', 'borderRadius': '10px'}"
                            class="animate__animated animate__zoomIn bg" @click="openWebsite(item)">
                        <h3>{{ item.moduleName }}</h3>
                    </a-card>
                </a-col>
            </a-row>
        </div>
    </div>

</template>
<script>
import {updateUserVisitApi} from '@/api/index'
import {setRedirectMoudleCode} from '@/utils/auth'

const cardColor = [
    {borderColor: 'rgba(22, 92, 205,1)', mainColor: 'rgba(22, 92, 205,0.5)'},
    {borderColor: 'rgba(255, 149, 0,1)', mainColor: 'rgba(22, 92, 205,0.5)'},
    {borderColor: 'rgba(0, 139, 139,1)', mainColor: 'rgba(22, 92, 205,0.5)'},
    {borderColor: 'rgba(24, 144, 255,1)', mainColor: 'rgba(22, 92, 205,0.5)'},
    {borderColor: 'rgba(245, 34, 45, 1)', mainColor: 'rgba(0, 183, 123,0.5)'},
    {borderColor: 'rgba(55, 83, 144, 1)', mainColor: 'rgba(55, 83, 144,0.5)'},
]
export default {
    props: {
        searchResult: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            cardColor,
            targetOffset: undefined,
            appObj: {
                src: require('../../../assets/image/test.png'),
                titile: '垃圾分类数据中台'
            },
            searchList: [],
        };
    },
    mounted() {
        this.targetOffset = window.innerHeight / 2;
    },
    methods: {
        openWebsite(e) {
            updateUserVisitApi(e.moduleCode).then(res => {
                setRedirectMoudleCode(e.moduleCode)
                if (e.baseUrl.endsWith('8082')) {
                    const route = this.$router.resolve({path: '/area/home/index', query: {moduleCode: e.moduleCode}})
                    window.open(route.href, '_blank')
                    return
                } else if (e.baseUrl.endsWith('8101')) {
                    const route = this.$router.resolve({
                        path: '/street/streetHome/streetIndex',
                        query: {moduleCode: e.moduleCode}
                    })
                    window.open(route.href, '_blank')
                    return
                } else if (e.baseUrl.endsWith('8100')) {
                    const route = this.$router.resolve({
                        path: '/community/centerHome/centerIndex',
                        query: {moduleCode: e.moduleCode}
                    })
                    window.open(route.href, '_blank')
                    return
                } else if (e.baseUrl.endsWith('8089')) {
                    const route = this.$router.resolve({
                        path: '/viewport/viewport',
                        query: {moduleCode: e.moduleCode}
                    })
                    window.open(route.href, '_blank')
                    return
                } else if (e.baseUrl.endsWith('8081')) {
                    if (e.level === 2) {
                        const route = this.$router.resolve({
                            path: '/viewport/areaMapView',
                            query: {moduleCode: e.moduleCode}
                        })
                        window.open(route.href, '_blank')
                        return
                    } else {
                        const route = this.$router.resolve({
                            path: '/street/viewport/viewport',
                            query: {moduleCode: e.moduleCode}
                        })
                        window.open(route.href, '_blank')
                        return
                    }
                } else if (e.baseUrl.endsWith('8087')) {
                    const route = this.$router.resolve({
                        path: '/user/managementHome/managementIndex',
                        query: {moduleCode: e.moduleCode}
                    })
                    window.open(route.href, '_blank')
                    return
                } else if (e.baseUrl.endsWith('8086')) {
                    const route = this.$router.resolve({
                        path: '/data-service/serviceHome/serviceIndex',
                        query: {moduleCode: e.moduleCode}
                    })
                    window.open(route.href, '_blank')
                    return
                }

                window.open(e.baseUrl + '/#/?moduleCode=' + e.moduleCode, "_blank")
            })
        }
    }
};
</script>

<style lang="scss">
.test-card-box {
  height: 74px;
  // background: rgba(255, 101, 79, 0.2);
  border-radius: 7px;
  border-left-width: 1px;
  border-left: inset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    box-shadow: 10px 5px 5px #ccc;
    cursor: pointer;
    transition: all 0.2s ease-out 0.2s;
  }

  // border-left-color: #ff654f;
  .test-card-content {
    text-align: center;
    color: #000;
    font-size: 19px;
    width: 100%;
    padding: 10px;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 这里是超出几行省略 */
    overflow: hidden;

  }

  .bg {
    background-image: url('../../../assets/image/bg_1.png');
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: auto 100%;

    h3 {
      font-weight: 600;
    }
  }
}
</style>