<template>
  <a-tabs type="card" @change="callback" class="navi-tab">
    <a-tab-pane :key="index" :tab="item.tab" v-for="(item, index) in cardsList">
       <a-row :gutter="20" style="padding: 10px;">
           <a-col :lg="12" :xs="24" :md="24" :xl="18"  style="padding: 10px;min-height: 100%; box-sizing: border-box;">
               <website/>
           </a-col>
           <a-col :lg="6" :xs="24" :md="12" :xl="6"  style="padding: 10px">
               <personal-card/>
           </a-col>
       </a-row>
    </a-tab-pane>
  </a-tabs>
</template>
<script>
import personalCard from '../personalCard/index.vue'
import website from './component/website.vue'
export default {
    components: { website, personalCard},
    data() {
        return {
            cardsList: [
                {
                    key: '1',
                    tab: '应用导航'
                }
            ]
    };
  },
  mounted() {

  },
  methods: {
    callback(key) {
      // console.log(key);
    },
  },
};
</script>

<style lang="scss">
.navi-tab{
  height: calc(100% - 70px);
  overflow: auto;
}

</style>