<template>
    <div>
        <div class="systemNavigation">
            <div style="flex: 1;">
                <div v-if="myAppList.length > 0 ">
                    <h3 style="font-weight:bolder;">我的权限</h3>
                    <a-row :gutter="20" diretion="horizontal" type="flex">
                        <a-col v-for="(item) in myAppList" :key="item.moduleName" :lg="5" :md="12" :xl="5" :xs="24"
                               style="margin: 10px;">
                            <a-card :style="{'borderLeftWidth': '5px', 'borderLeft': 'solid',  'borderLeftColor': cardColor[item.level].borderColor, 'fontSize': '15px', 'borderRadius': '10px', 'backgroundColor': '#F2F6FF'}"
                                    class="animate__animated animate__zoomIn bg"
                                    hoverable
                                    @click="openWebsite(item)">
                                <h3>{{ item.moduleName }}</h3>
                            </a-card>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>
        <a-pagination v-model="current" :pageSize="16" :total="total" class="navi-pagination" show-less-items
                      @change="changePage"/>


        <a-card v-if="user.username === 'admin'"
                :style="{'borderLeftWidth': '5px', 'borderLeft': 'solid',  'borderLeftColor': cardColor[0].borderColor, 'fontSize': '15px', 'borderRadius': '10px', 'backgroundColor': '#F2F6FF'}"
                class="animate__animated animate__zoomIn bg"
                hoverable
                @click="toRootManagement">
            <h3>超管平台</h3>
        </a-card>
    </div>
</template>
<script>
import {queryRecentUserAuthModuleApi, queryUserAuthModuleApi, updateUserVisitApi} from '@/api'
import {setRedirectMoudleCode} from '@/utils/auth'

const cardColor = [
    {borderColor: 'rgba(22, 92, 205,1)', mainColor: 'rgba(22, 92, 205,0.5)'},
    {borderColor: 'rgba(255, 149, 0,1)', mainColor: 'rgba(22, 92, 205,0.5)'},
    {borderColor: 'rgba(0, 139, 139,1)', mainColor: 'rgba(22, 92, 205,0.5)'},
    {borderColor: 'rgba(24, 144, 255,1)', mainColor: 'rgba(22, 92, 205,0.5)'},
    {borderColor: 'rgba(245, 34, 45, 1)', mainColor: 'rgba(0, 183, 123,0.5)'},
    {borderColor: 'rgba(55, 83, 144, 1)', mainColor: 'rgba(55, 83, 144,0.5)'},
]
export default {
    data() {
        return {
            cardColor,
            current: 1,
            total: 0,
            targetOffset: undefined,
            appObj: {
                src: require('../../../assets/fengmian.png'),
                titile: '垃圾分类数据中台'
            },
            recentList: [],
            myAppList: [],
            params: {
                page: 1,
                limit: 16
            },
            user: {}
        };
    },
    mounted() {
        this.targetOffset = window.innerHeight / 2;
        this.user = JSON.parse(localStorage.getItem('person-info')) || {username: '未知', mail: '未知'}
    },
    methods: {
        toRootManagement() {
            const route = this.$router.resolve({
                path: '/management/base/residentialMgt',
            })
            window.open(route.href, '_blank')
        },
        changePage(e) {
            this.current = e
            this.params.page = e
            this.current = e
            this.queryUserAuthModule()
        },
        queryUserAuthModule() {
            queryUserAuthModuleApi({
                ...this.params,
                baseUrl: window.location.protocol + '//' + window.location.host
            }).then(res => {
                this.myAppList = res.result.content
                this.total = res.result.totalElements

            })
        },
        queryRecentUserAuthModule() {
            queryRecentUserAuthModuleApi({baseUrl: window.location.protocol + '//' + window.location.host}).then(res => {
                this.recentList = res.result.modules
            })
        },
        openWebsite(e) {
            console.log(e)
            updateUserVisitApi(e.moduleCode).then(res => {
                setRedirectMoudleCode(e.moduleCode)
                if (e.baseUrl.endsWith('8082')) {
                    const route = this.$router.resolve({path: '/area/home/index', query: {moduleCode: e.moduleCode}})
                    window.open(route.href, '_blank')
                    return
                } else if (e.baseUrl.endsWith('8101')) {
                    const route = this.$router.resolve({
                        path: '/street/streetHome/streetIndex',
                        query: {moduleCode: e.moduleCode}
                    })
                    window.open(route.href, '_blank')
                    return
                } else if (e.baseUrl.endsWith('8100')) {
                    const route = this.$router.resolve({
                        path: '/community/centerHome/centerIndex',
                        query: {moduleCode: e.moduleCode}
                    })
                    window.open(route.href, '_blank')
                    return
                } else if (e.baseUrl.endsWith('8089')) {
                    const route = this.$router.resolve({
                        path: '/viewport/viewport',
                        query: {moduleCode: e.moduleCode}
                    })
                    window.open(route.href, '_blank')
                    return
                } else if (e.baseUrl.endsWith('8081')) {
                    if (e.level === 2) {
                        const route = this.$router.resolve({
                            path: '/viewport/areaMapView',
                            query: {moduleCode: e.moduleCode}
                        })
                        window.open(route.href, '_blank')
                        return
                    } else {
                        const route = this.$router.resolve({
                            path: '/street/viewport/viewport',
                            query: {moduleCode: e.moduleCode}
                        })
                        window.open(route.href, '_blank')
                        return
                    }
                } else if (e.baseUrl.endsWith('8087')) {
                    const route = this.$router.resolve({
                        path: '/user/managementHome/managementIndex',
                        query: {moduleCode: e.moduleCode}
                    })
                    window.open(route.href, '_blank')
                    return
                } else if (e.baseUrl.endsWith('8086')) {
                    const route = this.$router.resolve({
                        path: '/data-service/serviceHome/serviceIndex',
                        query: {moduleCode: e.moduleCode}
                    })
                    window.open(route.href, '_blank')
                    return
                }

                window.open(e.baseUrl + '/#/?moduleCode=' + e.moduleCode, "_blank")
            })
        }
    }
};
</script>

<style lang="scss">
.systemNavigation {
  min-height: 500px;
  margin-top: 3%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.navi-pagination {
  text-align: right !important;
}

.test-card-box {
  height: 74px;
  // background: rgba(255, 101, 79, 0.2);
  border-radius: 7px;
  border-left-width: 1px;
  border-left: inset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    box-shadow: 10px 5px 5px #ccc;
    cursor: pointer;
    transition: all 0.2s ease-out 0.2s;
  }

  // border-left-color: #ff654f;
  .test-card-content {
    text-align: center;
    color: #000;
    font-size: 19px;
    width: 100%;
    padding: 10px;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 这里是超出几行省略 */
    overflow: hidden;

  }
}

.common-card {
  width: 100%;
  margin: auto;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #FFFFFF;
  margin-bottom: 33px;
  box-shadow: #ededed 0px 0px 10px;
  padding: 1.25rem;
  // display: none;
}

.common-card:hover {
  transform: scale(1.1);
  transition-duration: 0.5s;
}

.common-card .icon {
  display: block;
  /* height: 100px; */
  /* line-height: 100px; */
  font-size: 30px;
  margin: 10px auto;
  color: #00398c;
  -webkit-transition: font-size 0.25s linear, width 0.25s linear;
  -moz-transition: font-size 0.25s linear, width 0.25s linear;
  transition: font-size 0.25s linear, width 0.25s linear;
  text-align: center;
}

.common-card p {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #b4b5b7;
  text-indent: 2em;
  line-height: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.common-card h3 {
  margin-top: 0rem;
  text-align: center;
}

.bg {
  background-image: url('../../../assets/image/bg_1.png');
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: auto 100%;

  h3 {
    font-weight: 600;
  }
}
</style>
