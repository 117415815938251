<template>
    <div class="index">
        <a-input placeholder="请输入关键词搜索" enter-button style="width: 30%;height: 46px;font-size: 17px;border-radius: 25px;" @change="onChangeInput" v-model="moduleName"/>
        <search-panel v-if="type == 'search'" :searchResult="searchResult"/>
        <comm-panel v-else ref="comm"/>
    </div>
</template>

<script>
import searchPanel from './searchPanel.vue'
import commPanel from './commPanel.vue'
import { queryUserAuthModuleApi } from '@/api/index'
export default {
    components: { commPanel, searchPanel},
    data() {
        return {
            type: 'init',
            moduleName: '',
            searchResult: []
        }
    },
    mounted() {
        // this.$getUserInfo().then(res => {
            this.$refs.comm.queryUserAuthModule()
            // this.$refs.comm.queryRecentUserAuthModule()
        // })


    },
    methods: {
        onChangeInput(e) {
            if(e.target.value == '') {
                this.type = 'init'
                setTimeout(() => {
                    this.$refs.comm.queryUserAuthModule()
                    // this.$refs.comm.queryRecentUserAuthModule()
                }, 200)
            } else {
                this.type = 'search'
                this.queryUserAuthModule()
            }
        },
        queryUserAuthModule() {
            queryUserAuthModuleApi({moduleName: this.moduleName, page: 0, pageSize: 5, baseUrl: window.location.protocol + '//' + window.location.host }).then(res => {
                this.searchResult = res.result.content
            })
        },
    }
}
</script>
<style>
.index{
    /* background: #F5F7F9; */
    padding: 15px;
    box-sizing: border-box;
    width: 100%;
    min-height: 100%;
}
</style>
