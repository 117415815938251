<template>
    <div>
        <a-card hoverable>
            <template slot="actions" class="ant-card-actions">
            </template>
            <a-card-meta :description="user.mail" :title="user.username">
                <a-avatar
                        slot="avatar"
                        :src="avatarImg"
                />
            </a-card-meta>
        </a-card>
        <a-card style="margin-top: 10px;" title="最近访问">
            <a-list :data-source="recentList" item-layout="horizontal" style="max-height: 400px;overflow:auto;">
                <a-list-item slot="renderItem" slot-scope="item, index">
                    <a slot="actions" @click="openWebsite(item)">点击前往</a>

                    <a-list-item-meta
                    >
                        <a slot="title">
                            <span class="recentAvatar">{{ item.moduleName.substring(0, 1) }}</span>
                            {{ item.moduleName }}
                        </a>
                    </a-list-item-meta>
                </a-list-item>
            </a-list>
        </a-card>
    </div>
</template>

<script>
import {queryRecentUserAuthModuleApi, updateUserVisitApi} from '@/api/index'
import {setRedirectMoudleCode} from '@/utils/auth'

export default {
    data() {
        return {
            user: {},
            recentList: [],
            notice: require('@/assets/icons/公告.png'),
            avatarImg: require('@/assets/image/user.png'),
        }
    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem('person-info')) || {username: '未知', mail: '未知'}
        this.queryRecentUserAuthModule()
    },
    methods: {
        queryRecentUserAuthModule() {
            queryRecentUserAuthModuleApi({baseUrl: window.location.protocol + '//' + window.location.host}).then(res => {
                this.recentList = res.result.modules
            })
        },
        openWebsite(e) {
            updateUserVisitApi(e.moduleCode).then(res => {
                setRedirectMoudleCode(e.moduleCode)

                if (e.baseUrl.endsWith('8082')) {
                    const route = this.$router.resolve({path: '/area/home/index', query: {moduleCode: e.moduleCode}})
                    window.open(route.href, '_blank')
                    return
                } else if (e.baseUrl.endsWith('8101')) {
                    const route = this.$router.resolve({
                        path: '/street/streetHome/streetIndex',
                        query: {moduleCode: e.moduleCode}
                    })
                    window.open(route.href, '_blank')
                    return
                } else if (e.baseUrl.endsWith('8100')) {
                    const route = this.$router.resolve({
                        path: '/community/centerHome/centerIndex',
                        query: {moduleCode: e.moduleCode}
                    })
                    window.open(route.href, '_blank')
                    return
                } else if (e.baseUrl.endsWith('8089')) {
                    const route = this.$router.resolve({
                        path: '/viewport/viewport',
                        query: {moduleCode: e.moduleCode}
                    })
                    window.open(route.href, '_blank')
                    return
                } else if (e.baseUrl.endsWith('8081')) {
                    if (e.level === 2) {
                        const route = this.$router.resolve({
                            path: '/viewport/areaMapView',
                            query: {moduleCode: e.moduleCode}
                        })
                        window.open(route.href, '_blank')
                        return
                    } else {
                        const route = this.$router.resolve({
                            path: '/street/viewport/viewport',
                            query: {moduleCode: e.moduleCode}
                        })
                        window.open(route.href, '_blank')
                        return
                    }
                } else if (e.baseUrl.endsWith('8087')) {
                    const route = this.$router.resolve({
                        path: '/user/managementHome/managementIndex',
                        query: {moduleCode: e.moduleCode}
                    })
                    window.open(route.href, '_blank')
                    return
                } else if (e.baseUrl.endsWith('8086')) {
                    const route = this.$router.resolve({
                        path: '/data-service/serviceHome/serviceIndex',
                        query: {moduleCode: e.moduleCode}
                    })
                    window.open(route.href, '_blank')
                    return
                }

                window.open(e.baseUrl + '/#/?moduleCode=' + e.moduleCode, "_blank")
            })
        }
    }
}
</script>

<style lang="scss">
.service-card {
  width: 33.333%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    width: 45px;
    height: 45px;
    margin: auto;
    margin-bottom: 10px;
  }
}

.ant-card-head {
  background: darkcyan;
  color: #fff;
  font-weight: bolder;

}

.recentAvatar {
  background: rgb(18, 150, 219);
  color: rgb(255, 255, 255);
  padding: 8px;
  font-size: 17px;
  border-radius: 50%;
  margin: 10px;
}
</style>